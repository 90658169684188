<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="testHeaders"
                    :loading="$store.getters['testReceipt/loading']"
                    :items="$store.getters['testReceipt/testReceipts']"
                    :search="searchTestReceipt"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Test Report Entry</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form @submit.prevent="() => false" class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Test Receipt"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchTestReceipt"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.doctor.display_text`]="{ item }">
                        <span v-if="item.doctor == null">N/A</span>
                        <span v-else>{{ item.doctor.display_text }}</span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon @click="testReportEntry(item)" small color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Report Entry</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon @click="patientBarcode(item)" class="ml-1" small color="#000" v-on="on">mdi-barcode</v-icon>
							</template>
							<span>Barcode</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <test-report-entry-modal :testReceipt="testReceipt" ref="testReportEntryModal"></test-report-entry-modal>
        <patient-barcode-modal :testReceipt="testReceipt" ref="patientBarcodeModal"></patient-barcode-modal>

    </v-container>
</template>

<script>
import TestReportEntryModal from '@/components/TestReportEntry'
import PatientBarcodeModal from '@/components/PatientBarcodes'

export default {
    name: 'TestReportEntry',
    components: {
        TestReportEntryModal,
        PatientBarcodeModal,
    },
    data: ()=> ({
        searchTestReceipt: '',
        testHeaders: [
            { text: 'Receipt ID', value: 'code' },
            { text: 'Receipt Date', value: 'bill_date' },
            { text: 'Patient ID', value: 'patient.code' },
            { text: 'Patient', value: 'patient.display_text' },
            { text: 'Referred By', value: 'doctor.display_text' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],

        testReceipt: null,
    }),
    created() {
        this.$store.dispatch('testReceipt/getTestReceipts', {isCompleted: false})
    },
    methods: {
        testReportEntry(item) {
            this.testReceipt = item
            this.$refs.testReportEntryModal.dialog = true
        },
        patientBarcode(item) {
            this.testReceipt = item
            this.$refs.patientBarcodeModal.dialog = true
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>