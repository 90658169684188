<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title> Test Investigation Entry </v-card-title>
        <v-card-text>
          <v-container>
            <ckeditor v-model="investigation" :config="editorConfig"></ckeditor>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveTestInvestigation"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CKEditor from 'ckeditor4-vue'

export default {
  props: ["testReceiptDetails"],
  components: {
    ckeditor: CKEditor.component
  },
  data: () => ({
    dialog: false,
    editorConfig: {
      toolbar: [ ['Styles','Format','Bold','Italic', 'NumberedList', 'BulletedList', 'Table', 'Source'] ]
    },

    investigation: '',
    testReceiptDetailsId: null
  }),
  watch: {
    testReceiptDetails(details) {
      if (details.testReceiptDetailsInvestigation) {
        this.investigation = details.testReceiptDetailsInvestigation;
      } else {
        this.investigation = details.testInvestigation;
      }
      this.testReceiptDetailsId = details.testReceiptDetailsId;
    }
  },
  methods: {
    async saveTestInvestigation() {
      if (this.testReceiptDetailsId == null) {
        alert('Something went wrong!');
        return
      }

      if (this.investigation == '') {
        alert('Investigation field is required');
        return
      }

      let res = await this.$store.dispatch('testReceipt/saveTestInvestigation', {
        id: this.testReceiptDetailsId, investigation: this.investigation
      });

      if (res) this.dialog = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 0;
}
.v-card__actions {
    padding-top: 0;
}
</style>