var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"custom-data-table elevation-1",attrs:{"dense":"","headers":_vm.testHeaders,"loading":_vm.$store.getters['testReceipt/loading'],"items":_vm.$store.getters['testReceipt/testReceipts'],"search":_vm.searchTestReceipt},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","color":"white","elevation":1,"height":"30px"}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Test Report Entry")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return (function () { return false; })($event)}}},[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search Test Receipt","append-icon":"mdi-magnify"},model:{value:(_vm.searchTestReceipt),callback:function ($$v) {_vm.searchTestReceipt=$$v},expression:"searchTestReceipt"}})],1)],1)]},proxy:true},{key:"item.doctor.display_text",fn:function(ref){
var item = ref.item;
return [(item.doctor == null)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.doctor.display_text))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.testReportEntry(item)}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Report Entry")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":"#000"},on:{"click":function($event){return _vm.patientBarcode(item)}}},on),[_vm._v("mdi-barcode")])]}}],null,true)},[_c('span',[_vm._v("Barcode")])])]}}],null,true)})],1)],1),_c('test-report-entry-modal',{ref:"testReportEntryModal",attrs:{"testReceipt":_vm.testReceipt}}),_c('patient-barcode-modal',{ref:"patientBarcodeModal",attrs:{"testReceipt":_vm.testReceipt}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }