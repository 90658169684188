<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          Test Report Entry
          <v-spacer></v-spacer>
          <small>{{ testReceipt != null ? testReceipt.code : '' }}</small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-simple-table dense class="text-center">
                <template>
                    <thead>
                        <tr>
                            <th class="text-center">Completed</th>
                            <th class="text-center">Test Name</th>
                            <th class="text-center">Delivery Date</th>
                            <th class="text-center">Completed Note</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in testReceiptDetails" :key="i">
                            <td>
                              <input 
                                type="checkbox" 
                                v-model="item.isReportCompleted" 
                                :disabled="item.isTestCompleted"
                              >
                            </td>
                            <td>{{ item.testName }}</td>
                            <td>{{ item.deliveryDateTime }}</td>
                            <td>
                              <textarea
                                rows="1"
                                outlined
                                class="mt-1"
                                :disabled="!item.isReportCompleted"
                                v-model="item.reportCompletedNote"
                              ></textarea>
                            </td>
                            <td>
                              <v-btn
                                :color="item.testInvestigationStatus ? 'success' : 'primary'"
                                x-small
                                class="mr-1"
                                @click="showTestInvestigation(item)"
                              >
                                Investigation
                              </v-btn>
                              <v-btn
                                color="secondary"
                                x-small
                                :to="`/lab/investigation-report/${item.testReceiptDetailsId}`"
                              >
                                Report
                              </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveCompletedTest"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <test-investigation-entry :testReceiptDetails="singleTestReceiptDetails" ref="testInvestigationModal"></test-investigation-entry>
  </v-row>
</template>

<script>
import TestInvestigationEntry from './TestInvestigationEntry'

export default {
  props: ["testReceipt"],
  components: {
    TestInvestigationEntry
  },
  data: () => ({
    dialog: false,
    investigationDialog: false,
    testReceiptDetails: [],
    singleTestReceiptDetails: Object
  }),
  watch: {
    testReceipt() {
      let testReceiptDetailsData = []
      this.testReceipt.test_receipt_details.forEach(el => {
        testReceiptDetailsData.push({
          testReceiptDetailsId: el.id,
          testId: el.test.id,
          testName: el.test.name,
          testInvestigation: el.test.investigation,
          testReference_values: el.test.reference_values,
          testReceiptDetailsInvestigation: el.investigation,
          testInvestigationStatus: el.investigation_status,
          deliveryDateTime: el.delivery_date_time,
          isReportCompleted: el.report_completed_at != null ? true : false,
          isTestCompleted: el.report_completed_at != null ? true : false,
          reportCompletedNote: el.report_completed_note,
        })
      })
      this.testReceiptDetails = testReceiptDetailsData
    }
  },
  methods: {
    async saveCompletedTest() {
      let completed = this.testReceiptDetails.filter(item => item.isReportCompleted == true)
      if (!completed.length) return

      let res = await this.$store.dispatch('testReceipt/saveCompletedTest', {
        details: this.testReceiptDetails, id: this.testReceipt.id
      });

      if (res) this.dialog = false;
    },

    showTestInvestigation(item) {
      this.singleTestReceiptDetails = item;
      this.$refs.testInvestigationModal.dialog = true;
    }
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid rgb(180, 180, 180) !important;
  padding: 2px 5px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: unset;
}
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 0;
}
</style>