<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="250px"
    >
      <v-card>
        <v-card-title>
          <span>Barcodes (<small>{{ testReceipt != null ? testReceipt.code : '' }}</small>) </span>
        </v-card-title>
        <v-card-text class="mb-0 pb-0">
          <v-container id="printContent" style="padding: 0 15px">
            <div v-for="(t, i) in testDetails.length" :key="i" style="line-height:0">
                <vue-barcode 
                    :value="testReceipt.patient.code"
                    :options="{ displayValue: false, height: 35, width: 1.5, }"
                    tag="img"
                >
                    Barcode print failed
                </vue-barcode>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="printBarcodes('printContent')"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode'

export default {
  props: ["testReceipt"],
  components: {
    VueBarcode,
  },
  data: () => ({
    dialog: false,
    testDetails: [],
  }),
  watch: {
    testReceipt(item) {
        this.testDetails = item.test_receipt_details
    }
  },
  methods: {
    printBarcodes(elem) {
      var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`
            <html><head><title>Guest Registration Form</title>
            <style>

            </style>
        `);

        mywindow.document.write('</head><body onload="loadHandler()">');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
            mywindow.document.execCommand('print');
            mywindow.close();
        } else {
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();
        }

        return true;
    }
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid rgb(180, 180, 180) !important;
  padding: 2px 5px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: unset;
}
</style>